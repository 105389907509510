import React from 'react'
import {Link} from 'react-scroll'
import {HiArrowNarrowRight} from 'react-icons/hi'

const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-[#0a192f]'>
        {/* Container */}
        <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
            <p className='text-blue-400'>Hello , my name is </p>
            <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>Amaury </h1>
            <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>I'm an IT Student</h2>
            <p className='text-[#8892b0] py-4 max-w-[700px]'>Specialized in software development</p>
            <div>
                <button className='text-white border-2 px-6 py-3 my-2 flex items-center hover:bg-blue-400'><Link to='work' smooth={true} duration={500}>View Work</Link> <HiArrowNarrowRight className='ml-2'/> </button>
            </div>
        </div>
    </div>
  );
};

export default Home;