import React from 'react'


import HTML from '../assets/Html.png';
import C from '../assets/C.png';
import Csharp from '../assets/Csharp.png';
import Python from '../assets/Python.png';
import ReactIcon from '../assets/React.png'
import Github from '../assets/Github.png'
import Java from '../assets/Java.png'
import Angular from '../assets/Angular.png'

const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        {/*Container*/}
        <div className=' max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div>
                <p className='text-4xl font-bold inline border-b-4 border-blue-400'>Experience</p>
                <p className='py-4'>Technology I've worked with</p>
            </div>

            <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
              <div className='shadow-md shadow-[#040C16] hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={HTML} alt="Html Icon" />
                <p className='my-4'>HTML</p>
              </div>
              <div className='shadow-md shadow-[#040C16] hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={Java} alt="Js Icon" />
                <p className='my-4'>Java</p>
              </div>
              <div className='shadow-md shadow-[#040C16] hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={ReactIcon} alt="React Icon" />
                <p className='my-4'>React</p>
              </div>
              <div className='shadow-md shadow-[#040C16] hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={Csharp} alt="Csharp Icon" />
                <p className='my-4'>C#</p>
              </div>
              <div className='shadow-md shadow-[#040C16] hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={C} alt="C Icon" />
                <p className='my-4'>C</p>
              </div>
              <div className='shadow-md shadow-[#040C16] hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={Python} alt="Python Icon" />
                <p className='my-4'>Python</p>
              </div>
              <div className='shadow-md shadow-[#040C16] hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={Angular} alt="CSS Icon" />
                <p className='my-4'>Angular</p>
              </div>
              <div className='shadow-md shadow-[#040C16] hover:scale-110 duration-500'>
                <img className='w-20 mx-auto' src={Github} alt="Git Icon" />
                <p className='my-4'>GitHub</p>
              </div>
            </div>
        </div>
    </div>
  );
};

export default Skills;