import WorkIm from '../assets/WorkinProgress.png'
import Optimergo from '../assets/Optimergo.png'
import Urbalog from '../assets/Urbalog.png'

export const data=[
    {
        id:1,
        name:"Optimergo",
        image:Optimergo,
        github:"https://www.optimergo.com/",
        live:"https://www.optimergo.com/",
    },
    {
        id:2,
        name:"Urbalog",
        image:Urbalog,
        github:"https://www.ville-jeux.com/URBALOG.html",
        live:"https://www.youtube.com/watch?v=wqnELVCAOt4&feature=youtu.be",
    },
    {
        id:3,
        name:"SurvivalBros",
        image:WorkIm,
        github:"https://github.com/teubamaf/SurvivalBros",
        live:"",
    },
    


]