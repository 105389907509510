import React from 'react'

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        <div className=' max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div>
            <p className='text-4xl font-bold inline border-b-4 border-blue-400'>About</p>
            </div>
            <div className='max-w-[1000px] w-full grid gap-8' >
                    <p className='w-full grid-2 gap-8 px-4 mt-10 text-xl'>
                        25-years-old French student currently in master degree in IT.<br/> My passion lies in object oriented programming, and I enjoy utilizing my skills to create innovative and efficient software solutions. <br/>In my free time, I love to indulge in my other passion - video games, playing and exploring different games across various genres.
                    </p>
                    <p className='w-full grid-2 gap-8 px-4 text-xl'>
                        Currently learning Godot 4.0 to create SurvivalBros and others stuff.<br/>
                    </p>
            </div>
        </div>
    </div>
  );
};

export default About;
